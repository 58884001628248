<template>
  <div class="reset">
     <div>
      <van-field
        v-model="userData.userName"
        name="OA员工"
        label="OA员工"
        placeholder="OA员工"
        :disabled="true"
      />
      <van-field
        v-model="userData.phone"
        name="登录手机"
        label="登录手机"
        placeholder="登录手机"
        :disabled="true"
      />
      <van-field
        v-model="userData.position"
        name="职位"
        label="职位"
        placeholder="职位"
        :disabled="true"
      />
      <van-field
        v-model="userData.currentstationName"
        name="部门(站)"
        label="部门(站)"
        placeholder="部门(站)"
        :disabled="true"
      />
    </div>
    <div class="tips">设置新密码</div>
    <div>
      <van-field
        v-model="formData.newPassword"
        label="新密码"
        placeholder="请输入"
        type="password"
        @blur="newPasswordBlur"
      />
      <van-field
        v-model="formData.againPassword"
        label="确认新密码"
        type="password"
        placeholder="请输入"
        @blur="againBlur"
      />
    </div>
    <div class="tips">设置签字码<span style="font-size:12px; margin-left:10px">(用于日后OA快捷审核)</span></div>
    <div>
      <van-field
        v-model="formData.newSignature"
        label="签字码"
        type="password"
        placeholder="请输入"
        @blur="newSignatureBlur"
      />
      <van-field
        v-model="formData.againSignature"
        label="确认签字码"
        type="password"
        placeholder="请输入"
        @blur="againSignatureBlur"
      />
    </div>
    <div style="margin: 16px">
      <van-button type="info" style="width: 100%" @click="onSubmit"
        >完成重置</van-button
      >
    </div>
  </div>
</template>

<script>
import { Notify, Button, Field, Form } from "vant";
import { setopenidandpwd } from "@/api/common/user";
import { getinfo } from "@/api/common/user";
import { getCode } from "@/utils/common";
export default {
  name: "resetPassword",
  components: {
    [Notify.name]: Notify,
    [Button.name]: Button,
    [Field.name]: Field,
    [Form.name]: Form,
  },
  data() {
    return {
      isLoading: false,
      userData:{
         userName: "胡英俊",
        phone: "15736109936",
        position: "站长",
        currentstationName: "沙溪收费站",
      },
      formData: {
        newPassword: "",
        againPassword: "",
        newSignature: "",
        againSignature: "",
      },
      newErrMessage: "", //新密码错误提示
      againErrMessage: "", //再次输入密码错误提示
    };
  },
  mounted() {
    this.getDetails();
  },
  created() {},
  methods: {
    newPasswordBlur() {
      let pwdRegex = new RegExp(
        /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/g
      );
      if (!this.formData.newPassword) {
        Notify({ type: "danger", message: "新密码不能为空" });
        return false;
      } else if (
        !pwdRegex.test(this.formData.newPassword && this.formData.newPassword)
      ) {
        Notify({
          type: "danger",
          message: "新密码必须长度大于8位，同时包含字母和数字，请重新填写",
        });
        return false;
      }
    },
    getDetails() {
      this.isShow = false;
        getinfo().then((res) => {
          console.log("res", res);
          this.userData = res.data;
        });
    },
    againBlur() {
      let pwdRegex = new RegExp(
        /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/g
      );
      if (!this.formData.againPassword) {
        Notify({ type: "danger", message: "再次输入的新密码不能为空" });
        return false;
      } else if (
        !pwdRegex.test(
          this.formData.againPassword && this.formData.againPassword
        )
      ) {
        Notify({
          type: "danger",
          message:
            "再次输入的新密码必须长度大于8位，同时包含字母和数字，请重新填写",
        });
        return false;
      }
    },
    newSignatureBlur (){

    },
    againSignature (){
      
    },
    //   提交
    onSubmit() {
      if(this.formData.newPassword == ''){
        Notify({
          type: "danger",
          message: "请输入密码",
        });
        return false;
      }
      if(this.formData.newSignature == ''){
        Notify({
          type: "danger",
          message: "请输入签字码",
        });
        return false;
      }
      if (
        this.formData.newPassword.trim() != this.formData.againPassword.trim()
      ) {
        Notify({
          type: "danger",
          message: "两次密码输入不一致，请检查后在提交！",
        });
        return false;
      } else if (
        this.formData.newSignature.trim() != this.formData.againSignature.trim()
      ) {
        Notify({
          type: "danger",
          message: "两次签字码输入不一致，请检查后在提交！",
        });
        return false;
      }
       else {
        let obj = {
          wxCode: getCode() || "",
          NewPwd: this.formData.newPassword,
          SignatureCode: this.formData.newSignature,
        };
        setopenidandpwd({ ...obj })
          .then((res) => {
            if (res.code == 200) {
              this.$router.push({ name: "form-complete" });
            }
          })
          .catch((err) => {
            Notify({
              type: "danger",
              message: err.message,
            });
            return false;
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.reset {
 height: 100vh;
  background: #eef1f6;
}
.tips{
  padding:16px;
  color:#333;
  font-size: 14px;
}

</style>